import { useState, useEffect } from "react";
import WebRadio from "../webRadio/webRadio";
import { Helmet } from "react-helmet";

const HomePage = () => {

    const [displayWebRadio, setWebRadio] = useState(false)
    const [passwords, setPasswords] = useState()
    const [error, setError] = useState(false)
    const [account, setAccount] = useState('')

    
    useEffect(() => {
        async function fetchMyAPI() {
            let response = await fetch(
                `https://back.lnpwebradio.fr/api/password-fursacs`
            );
            response = await response.json();
            return response.data;
        }
        fetchMyAPI().then((data) => {
            const arr = []
            data.map((item) => arr.push(item.attributes.password) )
            setPasswords(arr)
        });
    }, []);

    function checkPassword(e) {
        e.preventDefault();
        if (passwords.includes(e.target.password.value) ){
            setWebRadio(true)
            setAccount(e.target.password.value)
        }
        else{
            setError(true)
        }
    }


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Fursac</title>
            </Helmet>
            {!displayWebRadio ?
                <div className="content">
                    <h1 className="title">FURSAC</h1>
                    <form className="form" action="submit" onSubmit={checkPassword}>
                        <input id="password" name="password" type="text" placeholder="Identifiant" />
                        <button className="submitBtn" type="submit">Valider</button>
                        {error && <div className="error">Mot de passe erroné</div>}
                    </form>
                </div>
                :
                <WebRadio account={account} />
            }
        </>
    )
}


export default HomePage