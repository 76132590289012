import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import { useState, useRef, useEffect } from "react";
import backApres from "./back/Aprèsmidi.mp4"
import backMatin from "./back/morninghd.mp4"
import backNuit from "./back/nighthd.mp4"

const WebRadio = ({ account }) => {

    const [data, setData] = useState()
    const [currentStatus, setCurrentStatus] = useState();
    const [src, setSrc] = useState();
    const [musicName, setMusicName] = useState();
    const [indexMusic, setIndexMusic] = useState(0);
    const [imageSrc, setimageSrc] = useState();
    const [reload, setReload] = useState(false);
    const [dayTime, setDayTime] = useState('')

    const player = useRef();

    const url = "https://back.lnpwebradio.fr/";

    useEffect(() => {
        const hour = new Date().getHours();

        if (hour > 7 && hour < 19) {
            if (hour < 12) {
                setDayTime("morning")
                setimageSrc(backMatin)
            }
            else {
                setDayTime("afternoon")
                setimageSrc(backApres)
            }
        }
        else {
            setDayTime("night")
            setimageSrc(backNuit)
        }
    })

    useEffect(() => {
        async function fetchMyAPI() {
            let response = await fetch(
                `https://back.lnpwebradio.fr/api/playlists/5?populate=*`
            );
            response = await response.json();
            setReload(!reload);
            return response.data;
        }
        fetchMyAPI().then((data) => {
            setData(data)
            getTheStatus(data);
        });
    }, []);

    useEffect(() => {
        if (player.current !== undefined && currentStatus !== undefined) {
            startPlayer();
        }
    }, [reload]);

    function getTheStatus(data) {
        setIndexMusic(0);
        let newDatas = randomize(data);
        setCurrentStatus(newDatas);
        setReload(!reload);
    }


    function randomize(dataSet) {
        let list = dataSet.attributes.musique.data.map((value, index) => index);
        list = list.sort(() => Math.random() - 0.5);
        const results = list.map(
            (number) => dataSet.attributes.musique.data[number]
        );
        return results;
    }


    function startPlayer() {
        let secondPartSrc = currentStatus[indexMusic].attributes.url;
        setSrc(url + secondPartSrc);
        setMusicName(currentStatus[indexMusic].attributes.name);
    }


    function endMusicCheckStatus() {
        nextMusic();
    }

    function nextMusic() {
        if (indexMusic >= currentStatus.length - 1) {
            getTheStatus(data)
        } else {
            setIndexMusic(indexMusic + 1);
        }
        let secondPartSrc = currentStatus[indexMusic].attributes.src;
        setSrc(url + secondPartSrc);
        setMusicName(currentStatus[indexMusic].attributes.name);
        setReload(!reload);
    }

    function previousMusic(e) {
        setIndexMusic(indexMusic - 1);
        let secondPartSrc = currentStatus[indexMusic].attributes.src;
        setSrc(url + secondPartSrc);
        setMusicName(currentStatus[indexMusic].attributes.name);
        setReload(!reload);
    }


    return (

        <>

            <div className="accountContainer"> <div>Compte</div> <p> {account} </p></div>

            <div className="webRadioContainer">
                <h1 className="title">FURSAC</h1>
                <div className="videoContainer">
                    <video key={imageSrc} width="190"
                        webkit-playsinline="true"
                        playsInline={true}
                        loop={true}
                        autoPlay={true}
                        muted={true}
                        className="videoBackground">
                        <source src={imageSrc} type="video/mp4" />
                    </video>
                </div>
                <p className="dayTime">{dayTime}</p>
                <div className="webRadioContent">

                    <div className="musicName">
                        {musicName ? (
                            <>
                                {musicName
                                    .replace(/ *\([^)]*\) */g, "")
                                    .replace("MPJournée", "")
                                    .replace("MPMatinée", "")
                                    .replace("MPSoirée", "")
                                    .replace(".mp3", "")
                                    .replace(/[\[\]']+/g, "")
                                    .replace("-", " ")
                                    .replace("_", " ")
                                    .replace("MPSoirée -", " ")}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="audioContainer">
                        <AudioPlayer
                            ref={player}
                            showSkipControls={false}
                            showJumpControls={false}
                            showFilledVolume={true}
                            playsInline={true}
                            src={src}
                            autoPlay
                            onClickNext={(e) => nextMusic(e)}
                            onClickPrevious={(e) => previousMusic(e)}
                            onEnded={(e) => endMusicCheckStatus(e)}
                            customProgressBarSection={[
                                RHAP_UI.PROGRESS_BAR,
                                RHAP_UI.CURRENT_TIME,
                                RHAP_UI.DURATION,
                            ]}
                        />

                    </div>
                </div>
            </div>
        </>
    );
}

export default WebRadio