import HomePage from "./homePage/homePage";
import './index.css'

function App() {
  return (
    <main className="main">
      <HomePage />
    </main>
  );
}

export default App;
